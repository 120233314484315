<template>
  <a-card class="card" :bordered="false">
    <base-page
      :queryFields="queryFields"
      :columns="columns"
      :rules="rules"
      :modelTitle="modelTitle"
      :formFields="formFields"
      :moduleName="moduleName"
      :getPageFun="getCommonPage"
      :deleteFun="deleteCommonInfo"
      :createFun="createCommonInfo"
      :updateFun="updateCommonInfo">
    </base-page>
  </a-card>
</template>
<script>
import { BasePage } from '@/components'
import { getCommonPage, createCommonInfo, updateCommonInfo, deleteCommonInfo } from '@/api/common'
export default {
  components: {
    BasePage
  },
  data() {
    return {
      queryFields: [
        {
          label: '供应商简称', name: 'short_name', fieldType: '', queryType: '%'
        }
      ],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 30
        },
        {
          title: '供应商简称',
          dataIndex: 'short_name'
        },
        {
          title: '供应商全称',
          dataIndex: 'full_name'
        },
        {
          title: '联系人',
          dataIndex: 'contact_name',
          width: 80
        },
        {
          title: '联系电话',
          dataIndex: 'contact_phone'
        },
        {
          title: '联系地址',
          dataIndex: 'contact_address'
        },
        {
          title: '描述',
          dataIndex: 'remark'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 120,
          scopedSlots: { customRender: 'action' }
        }
      ],
      rules: {
        name: [
          { required: true, message: '请输入供应商全称', trigger: 'blur' }
        ]
      },
      modelTitle: '供应商信息',
      formFields: [
        {
          label: '供应商简称', name: 'short_name', type: 'default'
        },
        {
          label: '供应商全称', name: 'full_name', type: 'default'
        },
        {
          label: '联系人', name: 'contact_name', type: 'default'
        },
        {
          label: '联系电话', name: 'contact_phone', type: 'default'
        },
        {
          label: '联系地址', name: 'contact_address', type: 'default', span: 24
        },
        {
          label: '描述', name: 'remark', type: 'textarea', span: 24
        }
      ],
      moduleName: 'supplier_info'
    }
  },
  methods: {
    getCommonPage,
    createCommonInfo,
    updateCommonInfo,
    deleteCommonInfo
  }
}
</script>
